import { lazy, Suspense } from "react";

import { createBrowserRouter, Outlet } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
//import Preloader from "./layout/Preloader";
import Footer from "./components/Footer";
import Header from "./components/InnerHeader";
import Preloader from "./layout/Preloader";
const Home = lazy(() => import("./ui/Home"));
const Career = lazy(() => import("./ui/Career"));
const Contact = lazy(() => import("./ui/Contact"));
const About = lazy(() => import("./ui/About"));
const Service = lazy(() => import("./ui/Service"));
const NoPage = lazy(() => import("./layout/NoPage"));

function AppLayout() {
  return (
    <>
      <Header />
      <Suspense fallback={<Preloader />}>
        <Outlet />
      </Suspense>
      <Footer />

      {/* <BrowserRouter>
        <Suspense fallback={<Preloader />}>
          <Routes>
            <Route exact path="/" element={Home} />
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Service />} />
            <Route path="careers" element={<Career />} />
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter> */}
    </>
  );
}

export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <NoPage />,

    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/services",
        element: <Service />,
      },
      {
        path: "/careers",
        element: <Career />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);

// ReactDOM.createRoot(document.getElementById("root")).render(
//   <RouterProvider router={appRouter} />
// );
export default AppLayout;
